h2 {
  margin: 0 0;
}
.base-arrow {
  content: ' ';
  height: 0;
  position: relative;
  top: 10px;
  width: 0;
  z-index: 1;
  border: medium solid white;
}

.timeline-circle {
  left: 50%;
}
.repo-list {
  list-style-type: none;
  display: flex;
  align-content: flex-start;
  padding: 20px 20px;

  &:nth-child(even) {
    div {
      justify-content: flex-end;
    }
    .arrowr {
      @extend .base-arrow;
      border-width: 10px 10px 10px 0;
      border-color: transparent white transparent transparent;
    }
    .show {
      animation: slideFromRight 1s;
    }
  }

  &:nth-child(odd) {
    div {
      justify-content: flex-start;
    }
    .arrowl {
      @extend .base-arrow;
      border-width: 10px 0 10px 10px;
      border-color: transparent transparent transparent white;
    }
    .show {
      animation: slideFromLeft 1s;
    }
  }
}

@keyframes slideFromRight {
  0% {
    opacity: 0;
    transform: translateX(50px);
  }

  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}

@keyframes slideFromLeft {
  0% {
    opacity: 0;
    transform: translateX(-50px);
  }

  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}

@media screen and (max-width: 800px) {
  .timeline-circle {
    left: 31px;
  }
  .content {
    width: 100%;
  }
  .repo-list {
    list-style-type: none;
    display: flex;
    align-content: flex-start;
    padding: 20px 20px;
    &:nth-child(even),
    &:nth-child(odd) {
      div {
        justify-content: flex-end;
      }
      .arrowr {
        content: ' ';
        height: 0;
        position: relative;
        top: 10px;
        width: 0;
        z-index: 1;
        border: medium solid white;
        border-width: 10px 10px 10px 0;
        border-color: transparent white transparent transparent;
      }

      .arrowl {
        content: '';
        border-width: 0 0 0 0;
      }
      .show {
        animation: slideFromRight 1s;
      }
    }
  }
}
